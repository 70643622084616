import React from "react"
import ReactPlayer from "react-player"

{
  /* TODO list technologies */
}
{
  /* TODO make below mobile friendly */
}

const Project = ({
  project: { title, desc, link, linkPreText, linkText, videoPath },
}) => (
  <div className="project-container">
    <span
      className="title"
      style={{ fontSize: 50, color: "aquamarine", marginBottom: 50 }}
    >
      {title}
    </span>
    <div className="calorie-cam-container">
      {videoPath ? (
        <ReactPlayer
          className="react-player fixed-bottom"
          url={videoPath}
          width="15vw"
          height="100%"
          controls={true}
          playing={true}
          loop={true}
          // style={{ display:'block'}}
          muted
        />
      ) : (
        <iframe
          src={link}
          width="100%"
          height="400"
          frameborder="0"
          allowfullscreen
        ></iframe>
      )}
      <p className="about" style={{ color: "#333", marginTop: 50 }}>
        {desc}
      </p>
      <p className="about" style={{ color: "white" }}>
        {linkPreText}
        <a href={link} target="_blank" className="project-link">
          {linkText}
        </a>
      </p>
    </div>
  </div>
)

export default Project
