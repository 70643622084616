import React from "react"
import Project from "../Project"

const projects = [
  {
    title: "Calorie Cam",
    desc: `Discover my React Native creation - a cutting-edge calorie counting
            application featuring image recognition. This app is your personal
            wellness companion, making it effortless to keep track of your daily
            calorie intake. With its built-in image recognition capabilities,
            simply snap a photo of your meal, and let the app handle the rest. It
            identifies the ingredients and calculates the nutritional value,
            streamlining your health and fitness journey. Explore how this app
            redefines the way we manage our diet and stay on track.`,
    link: "https://apps.apple.com/us/app/calorie-cam/id1511528894?ls=1",
    linkPreText: "Download:",
    linkText: "https://apps.apple.com/us/app/calorie-cam/",
    videoPath: "/videos/calorieCam.mp4",
  },
  {
    title: "Resume Builder AI",
    desc: `Resume Builder AI is a powerful and intuitive web application that leverages the OpenAI API 
      and HTML canvas to revolutionize the resume-building experience. 
      With its user-friendly interface and cutting-edge technology, 
      Resume Builder AI empowers individuals to create polished and professional resumes effortlessly.`,
    link: "https://rsume.xyz",
    linkText: "https://rsume.xyz",
    linkPreText: "Check it out:",
  },
]

const ProjectContainer = () => (
  <div id="code">
    <span
      className="title"
      style={{
        color: "white",
        fontSize: 44,
        margin: 50,
        padding: 20,
      }}
    >
      👨‍💻 Here are some examples of my work:
    </span>
    {projects.map(project => (
      <Project project={project} />
    ))}
  </div>
)

export default ProjectContainer
