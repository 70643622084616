import React from "react"
import GitHubIcon from "./GitHubIcon"
import InstagramIcon from "./InstagramIcon"

const Contact = () => (
  <div id="contact" className="contact-container">
    {/* TODO add border radius to contact container */}
    <ul className="contact-list">
      <li>
        <div className="contact-list-outer-container">
          <div className="contact-list-inner-container">
            <span className="contact-list-header">Joshua Jones</span>
            <span className="contact-list-sub-header">
              Creative Fullstack Developer
            </span>
            <div style={{ display: "flex", gap: 20 }}>
              <GitHubIcon />
              <InstagramIcon />
            </div>
          </div>

          <div className="contact-list-inner-container">
            <span className="contact-list-header get-in-touch">
              Get in Touch
            </span>
            <a
              className="contact-list-sub-header"
              href="mailto:mrjoshuajones@protonmail.com"
            >
              mrjoshuajones@protonmail.com
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
)

export default Contact
