import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

function InstagramIcon() {
  return (
    <a
      href="https://www.instagram.com/joshua.jones.smg"
      target="_blank"
      rel="noopener noreferrer"
      className="contact-list-item"
    >
      <FontAwesomeIcon icon={faInstagram} />
    </a>
  )
}

export default InstagramIcon
