import React from "react"

const About = () => (
  <div
    id="about"
    style={{
      marginTop: "-100vh",
      /* TODO debug  */ backgroundColor: "#FEECEB",
      height: "content",
      color: "grey",
    }}
  >
    <div className="aboutContainer">
      <span
        className="title"
        style={{
          color: "black",
          marginBottom: 20,
          fontSize: 50,
          marginTop: 50,
        }}
      >
        👋 Hi, I'm Josh!
      </span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p className="about">
          I'm a full-stack web developer with a passion for bringing ideas to
          life through code. I embarked on my coding journey at Haiku Academy, a
          coding bootcamp on the vibrant island of Koh Samui, Thailand. Since
          then, I've been on an incredible adventure as a developer, spending
          the last three years crafting digital experiences at{" "}
          <a
            href="https://www.boardera.ca/"
            target="_blank"
            style={{ color: "darkcyan" }}
          >
            Boardera
          </a>
          , a dynamic startup nestled in the heart of Waterloo, Ontario—often
          dubbed the "Silicon Valley of the North."
          <br />
          <br />
          My academic background is as diverse as my interests. I hold a joint
          major in philosophy and business, which has not only honed my critical
          thinking skills but also shaped me into a well-rounded problem solver.
          <br />
          <br />
          Beyond the world of coding, you'll find me deeply immersed in the
          realms of Jiu Jitsu, yoga, and strength training. I thrive on the
          physical and mental challenges they offer. And when I'm not at my desk
          or the gym, I'm out exploring the world, whether it's a leisurely
          walk, a long run, or a journey to new places.
          <br />
          <br />
          As a full-stack developer, my domain is JavaScript. With it, I've had
          the privilege of turning innovative concepts into digital realities. I
          believe that the web is a canvas, and code is my brush. My mission is
          to create experiences that are not just functional but delightful.
          <br />
          <br />
          Ready to embark on a digital journey with me? Let's explore the
          possibilities together!
        </p>
      </div>
      {/* } */}
    </div>
  </div>
)

export default About
