import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub } from "@fortawesome/free-brands-svg-icons"

function GitHubIcon() {
  return (
    <a
      href="https://github.com/JoshuaJoness"
      target="_blank"
      rel="noopener noreferrer"
      className="contact-list-item"
    >
      <FontAwesomeIcon icon={faGithub} />
    </a>
  )
}

export default GitHubIcon
