import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import About from "../components/About"
import Contact from "../components/Contact"
import ProjectContainer from "../components/ProjectContainer"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <div id="home" className="image" />
      <About />
      <ProjectContainer />
      <div className="imageTwo" />
      <Contact />
    </Layout>
  )
}

export default IndexPage
